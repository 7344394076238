﻿import * as $ from "jquery";
import "./hints.css";
import { addEventDelegate } from "../global/dom";

var hints = {
	init: function () {
		addEventDelegate(document, 'click', '.tum-hints-showHint', element => {
			let target = $(element).attr('data-contenttarget');

			var text = target ? $(target).html() : $(element).attr("data-content");
			var elem = $(element).closest(".tum-hints-hasHint");

			if ($(element).hasClass("show")) {
				hints.showHint(text, elem, true);
				return;
			}

			hints.showHint(text, elem, false, element);
			$(element).addClass("show");
		});

		addEventDelegate(document, 'click', ".tum-hints-hideHint", (element, e) => {
			e.preventDefault();
			$(".tum-hints-hintBlock").removeClass("show");
			$(".tum-hints-showHint").removeClass("show");
		});
	},
	showHint: function (txt, elem, close) {
		var hintBlock = $(elem).find(".tum-hints-hintBlock:last");

		$(".tum-hints-hintBlock").removeClass("show");
		$(".tum-hints-showHint").removeClass("show");

		if (close) {
			return;
		}

		hintBlock.html('<div class="d-flex justify-content-between"><span><i class="fa fa-question-circle tum-hints-hintIcon" aria-hidden="true"></i> <a href="#" rel="nofollow" class="tum-hints-hideHint">Закрыть</a></span><a href="javascript:void(0)" class="tum-hints-hideHint"><i class="fas fa-times tum-grey"></i></a></div>' + txt);
		hintBlock.addClass('tum-hints-hintBorder');
		hintBlock.addClass('mb-2');
		hintBlock.addClass("show");
	}
};

export default hints;